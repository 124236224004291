<template>
  <div>
    <Pane />

    <div class="container">
      <a-form
        :label-col="{ span: 6 }"
        :wrapper-col="{ span: 12 }"
        :colon="false"
        @submit="handleSubmit"
        :form="form"
      >
        <a-row>
          <a-col :lg="12" :md="12" :sm="24">
            <a-form-item label="标题">
              <a-input
                v-decorator="[
                  'title',
                  { rules: [{ required: true, message: '请输入！' }] },
                ]"
              />
            </a-form-item>
          </a-col>

          <a-col :lg="12" :md="12" :sm="24">
            <a-form-item label="类型">
              <a-radio-group
                v-decorator="[
                  'type',
                  {
                    rules: [
                      {
                        required: true,
                        message: '请选择！',
                      },
                    ],
                  },
                ]"
              >
                <a-radio
                  v-for="item in honestType"
                  :key="item.value"
                  :value="item.value"
                  >{{ item.name }}</a-radio
                >
              </a-radio-group>
            </a-form-item>
          </a-col>

          <a-col :lg="12" :md="12" :sm="24">
            <a-form-item label="来源">
              <a-input
                v-decorator="[
                  'source',
                  { rules: [{ required: true, message: '请输入！' }] },
                ]"
              />
            </a-form-item>
          </a-col>

          <a-col :lg="12" :md="12" :sm="24">
            <a-form-item label="发布时间">
              <a-date-picker
                placeholder=""
                v-decorator="[
                  'publishAt',
                  { rules: [{ required: true, message: '请选择！' }] },
                ]"
                style="width: 100%"
              />
            </a-form-item>
          </a-col>

          <a-col :lg="24" :md="24" :sm="24">
            <a-form-item :label-col="{ span: 3 }" :wrapper-col="{ span: 18 }">
              <span class="required" slot="label">正文</span>
              <div id="editor"></div>
            </a-form-item>
          </a-col>

          <a-col :lg="24" :md="24" :sm="24">
            <a-form-item
              label="附件"
              :label-col="{ span: 3 }"
              :wrapper-col="{ span: 18 }"
            >
              <FileUpload :multiple="false" @uploaded="uploaded">
                <a-button type="primary">上传</a-button>
              </FileUpload>

              <Padding />

              <a-table bordered :data-source="fileList" :pagination="false">
                <a-table-column title="文件名">
                  <template slot-scope="text">
                    <a :href="text.completePath" target="_blank">{{
                      $getFileName(text.completePath)
                    }}</a>
                  </template>
                </a-table-column>
                <a-table-column title="操作" align="center">
                  <template slot-scope="text, record, index">
                    <a href="#" class="danger" @click.prevent="remove(index)"
                      >删除</a
                    >
                  </template>
                </a-table-column>
              </a-table>
            </a-form-item>
          </a-col>
        </a-row>

        <div class="center">
          <a-space>
            <a-button htmlType="submit" type="primary" :loading="loading"
              >保存</a-button
            >
            <a-button @click="$close($route.path)">关闭</a-button>
          </a-space>
        </div>
      </a-form>
    </div>
  </div>
</template>

<script>
import E from "wangeditor";
import FileUpload from "@/components/file-upload";
import { fetchDetail, update } from "@/api/party";
import moment from "moment";
import { mapGetters } from "vuex";

export default {
  name: "editPartyHonest",

  components: {
    FileUpload,
  },

  data() {
    return {
      form: this.$form.createForm(this),
      editor: null,
      fileList: [],

      id: null,
      doc: {},

      loading: false,
    };
  },

  computed: {
    ...mapGetters("setting", ["findDataDict"]),
    honestType() {
      return this.findDataDict("honestType");
    },
  },

  mounted() {
    const editor = new E("#editor");

    editor.config.height = 400;
    editor.config.zIndex = 1;
    editor.config.placeholder = "";
    editor.config.uploadImgServer = "/api/common-service/file/upload";
    editor.config.uploadImgHeaders = {
      token: window.localStorage.getItem("token"),
    };
    editor.config.uploadFileName = "files";
    editor.config.uploadImgHooks = {
      customInsert: function (insertImgFn, result) {
        if (result && Array.isArray(result.data) && result.data.length > 0) {
          insertImgFn(result.data[0].completePath);
        }
      },
    };

    editor.config.uploadVideoServer = "/api/common-service/file/upload/video";

    editor.config.uploadVideoHeaders = {
      token: window.localStorage.getItem("token"),
    };
    editor.config.uploadVideoName = "files";

    editor.create();
    this.editor = editor;
  },

  beforeDestroy() {
    if (this.editor) {
      this.editor.destroy();
      this.editor = null;
    }
  },

  activated() {
    const { query } = this.$route;
    const { id } = query || {};

    if (id && this.id !== id) {
      this.id = id;
      fetchDetail({
        id,
      }).then((res) => {
        this.doc = res;
        this.form.setFieldsValue({
          title: res.title,
          type: res.type,
          source: res.source,
          publishAt: moment(res.publishAt),
        });

        if (this.editor) {
          this.editor.txt.html(res.content); // 重新设置编辑器内容
        }

        this.fileList =
          res.attachments && typeof res.attachments === "string"
            ? res.attachments.split(",").map((item) => {
                return {
                  completePath: item,
                };
              })
            : [];
      });
    }
  },

  methods: {
    uploaded(list) {
      this.fileList = [...this.fileList, ...list];
    },
    remove(index) {
      this.fileList.splice(index, 1);
    },

    handleSubmit(e) {
      e.preventDefault();

      const content = this.editor.txt.html();

      if (content.length === 0) {
        this.$message.error("请输入正文！");
        return;
      }

      this.form.validateFields((err, values) => {
        if (!err) {
          this.loading = true;
          update({
            id: this.id,
            ...this.doc,
            ...values,
            publishAt: values.publishAt.format("YYYY-MM-DD"),
            content,
            attachments: this.fileList
              .map((item) => item.completePath)
              .join(","),
            category: "discipline",
          })
            .then(() => {
              this.$close(this.$route.path);
            })
            .finally(() => {
              this.loading = false;
            });
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.container {
  background-color: #fff;
  padding: 30px 164px;
}

.center {
  margin-top: 91px;
}
</style>
